// extracted by mini-css-extract-plugin
export var article = "styles-module--article--5bd5e";
export var container = "styles-module--container--c9d7c";
export var content = "styles-module--content--bd95d";
export var desktop = "1340px";
export var giant = "2200px";
export var hasMeta = "styles-module--hasMeta--b99fb";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var meta = "styles-module--meta--dccb7";
export var metaGroup = "styles-module--metaGroup--279ff";
export var metaGroupHeading = "styles-module--metaGroupHeading--171f4";
export var mobile = "400px";
export var partnersFlex = "styles-module--partnersFlex--ada7f";
export var partnersFlexItem = "styles-module--partnersFlexItem--22e62";
export var separator = "styles-module--separator--1b38c";
export var sidebar = "styles-module--sidebar--cb495";
export var sidebarGroup = "styles-module--sidebarGroup--c1f35";
export var sidebarTag = "styles-module--sidebarTag--57614";
export var sidebarTags = "styles-module--sidebarTags--f0337";
export var sidebarTickets = "styles-module--sidebarTickets--03887";
export var sidebarTicketsSoldOut = "styles-module--sidebarTicketsSoldOut--639db";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var ticketWidget = "styles-module--ticketWidget--b2e19";
export var ticketWidgetActive = "styles-module--ticketWidgetActive--680b8";
export var ticketWidgetButton = "styles-module--ticketWidgetButton--f92c2";
export var ticketWidgetContent = "styles-module--ticketWidgetContent--bc114";