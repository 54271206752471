import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import cn from "classnames";
import { useScroll, useWindowDimensions } from "~hooks";
import { Banner, CardGrid } from "~slices";
import { BlockContent, Button, Grid, Image, Link, SEO } from "~components";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @page
 * Static page routes @ /*.
 */
const Event = ({ data: staticData }) => {
  // ---------------------------------------------------------------------------
  // hooks

  const { scrollTop } = useScroll();
  const { isDesktop, windowSize } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const ref = useRef();
  const [widgetActive, setWidgetActive] = useState(true);

  // ---------------------------------------------------------------------------
  // variables

  const { allSanityEvent, sanityEvent } = staticData;

  const {
    _rawBody: rawBody,
    ticketLink,
    venue,
    tags,
    artist,
    dates,
    tickets,
    accessibility,
    warnings,
    credits,
    additionalInfo,
    partners,
    relatedEvents
  } = sanityEvent;

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!ref?.current || typeof window === `undefined` || isDesktop) {
      return;
    }

    const { offsetTop } = ref.current;
    const boundingClientRect = ref.current.getBoundingClientRect();

    setWidgetActive(
      scrollTop < offsetTop + (boundingClientRect.height - windowSize.height)
    );
  }, [ref, scrollTop, windowSize]);

  // ---------------------------------------------------------------------------
  // render

  const hasMeta =
    accessibility || warnings || credits || additionalInfo || partners?.[0];

  const related = [];

  if (relatedEvents?.[0]) {
    related.push(...relatedEvents);
  }

  if (related.length < 3) {
    const remainingRelatedSlots = 3 - related.length;
    const relatedTagTitles = sanityEvent.tags.map((tag) => tag.title);

    const eventsToAdd = allSanityEvent.nodes
      .filter((event) => event.slug.current !== sanityEvent.slug.current)
      .filter((event) => !related.includes(event))
      .filter((event) => {
        const eventTagTitles = event.tags.map((tag) => tag.title);

        return eventTagTitles.some((tagTitle) =>
          relatedTagTitles.includes(tagTitle)
        );
      })
      .slice(0, remainingRelatedSlots);

    related.push(...eventsToAdd);

    const remainingAllSanityEvents = allSanityEvent.nodes
      .filter((event) => event.slug.current !== sanityEvent.slug.current)
      .filter((event) => !related.includes(event));
    related.push(...remainingAllSanityEvents.slice(0, remainingRelatedSlots));

    if (related.length > 3) {
      related.splice(3);
    }
  }

  console.log(`ticketLink: `, ticketLink);

  return (
    <div className={cn(styles.container)}>
      {!isDesktop && ticketLink && ticketLink !== `` && (
        <div
          className={cn(styles.ticketWidget, {
            [styles.ticketWidgetActive]: widgetActive
              ? styles.ticketWidgetActive
              : null
          })}
        >
          <Grid>
            <div className={styles.ticketWidgetContent}>
              <Link to={ticketLink}>
                <Button className={styles.ticketWidgetButton} color="green">
                  <span className="button-text">BUY TICKETS</span>
                </Button>
              </Link>
            </div>
          </Grid>
        </div>
      )}

      <Banner
        data={{
          image: sanityEvent.image,
          imageXS: sanityEvent.imageXS,
          heading: sanityEvent.title
        }}
      />

      <div ref={ref}>
        <Grid
          className={cn(styles.article, {
            [styles.hasMeta]: hasMeta ? styles.hasMeta : null
          })}
          node="article"
        >
          <aside className={styles.sidebar}>
            {ticketLink && ticketLink !== `` && (
              <Link to={ticketLink}>
                <Button className={styles.sidebarTickets} color="green">
                  <span className="button-text">BUY TICKETS</span>
                </Button>
              </Link>
            )}

            <div className={cn(styles.sidebarGroup)}>
              <h4 className={cn(`b2`)}>Artist</h4>
              <p className={cn(`b1`)}>{artist}</p>
            </div>

            <div className={cn(styles.sidebarGroup)}>
              <h4 className={cn(`b2`)}>Venue</h4>
              <p className={cn(`b1`)}>{venue}</p>
            </div>

            <div className={cn(styles.sidebarGroup)}>
              <h4 className={cn(`b2`)}>Dates</h4>
              <p className={cn(`b1`)}>{dates}</p>
            </div>

            <div className={cn(styles.sidebarGroup)}>
              <h4 className={cn(`b2`)}>Tickets</h4>
              <p className={cn(`b1`)}>{tickets}</p>
            </div>

            <div className={cn(styles.sidebarTags)}>
              {tags?.[0] &&
                tags.map((tag) => (
                  <Button className={styles.sidebarTag} transparent>
                    <span className="button-text">{tag.title}</span>
                  </Button>
                ))}
            </div>
          </aside>

          <div className={styles.content}>
            {rawBody && <BlockContent blocks={rawBody} />}
          </div>

          {hasMeta && (
            <>
              <div className={styles.separator} />

              <div className={styles.meta}>
                {accessibility && (
                  <div className={styles.metaGroup}>
                    <h4 className={cn(`b2`, styles.metaGroupHeading)}>
                      Accessibility
                    </h4>
                    <p className={cn(`b1`)}>{accessibility}</p>
                  </div>
                )}

                {warnings && (
                  <div className={styles.metaGroup}>
                    <h4 className={cn(`b2`, styles.metaGroupHeading)}>
                      Warnings
                    </h4>
                    <p className={cn(`b1`)}>{warnings}</p>
                  </div>
                )}

                {credits && (
                  <div className={styles.metaGroup}>
                    <h4 className={cn(`b2`, styles.metaGroupHeading)}>
                      Credits
                    </h4>
                    <p className={cn(`b1`)}>{credits}</p>
                  </div>
                )}

                {additionalInfo && (
                  <div className={styles.metaGroup}>
                    <h4 className={cn(`b2`, styles.metaGroupHeading)}>
                      Additional Info
                    </h4>
                    <p className={cn(`b1`)}>{additionalInfo}</p>
                  </div>
                )}

                {partners?.[0] && (
                  <div className={styles.metaGroup}>
                    <h4 className={cn(`b2`, styles.metaGroupHeading)}>
                      Partners
                    </h4>

                    <div className={styles.partnersFlex}>
                      {partners.map((partner, partnerIndex) => (
                        <figure
                          key={`partner-image-${partnerIndex}`}
                          className={styles.partnersFlexItem}
                        >
                          <Image image={partner} />
                        </figure>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </Grid>
      </div>

      <CardGrid
        data={{
          cards: related
        }}
      />
    </div>
  );
};

export default Event;

export const Head = ({ location, data: staticData }) => (
  <SEO
    location={location}
    site={staticData?.site || {}}
    sanitySeo={{
      ...staticData?.sanityEvent?.seo,
      title:
        staticData?.sanityEvent?.seo?.title || staticData?.sanityEvent?.title
    }}
  />
);

export const query = graphql`
  # todo: move this
  fragment SanityEventFragment on SanityEvent {
    _id
    title
    slug {
      current
    }

    _rawBody
    body {
      _rawChildren
    }

    artist
    ticketLink
    title
    sortableDate
    dates
    datesShort
    tickets
    ticketsShort
    venue

    tags {
      title
    }

    image {
      asset {
        gatsbyImageData
      }
    }

    imageXS {
      asset {
        gatsbyImageData
      }
    }

    cardImage {
      asset {
        gatsbyImageData
      }
    }

    accessibility
    warnings
    credits
    additionalInfo

    partners {
      asset {
        gatsbyImageData
      }
    }

    seo {
      title
      description
      keywords
      image {
        asset {
          gatsbyImageData
        }
      }
    }
  }

  query Event($id: String!) {
    site {
      ...SiteFragment
    }

    allSanityEvent {
      nodes {
        ...SanityEventFragment
      }
    }

    sanityEvent(id: { eq: $id }) {
      ...SanityEventFragment

      relatedEvents {
        ...SanityEventFragment
      }
    }
  }
`;
